import React from 'react';
import { Box, Grid, Typography, TextField, Button, Paper, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import ContactImage from '../../assets/Images/Contacts/contact1.jpg'; // Replace with your image path

export default function Contacts() {
  return (
    <Box sx={{ width: '100%', py: 4 }}>
      <Grid container spacing={4} alignItems="stretch">
        {/* Left side: Image with details */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 4, textAlign: 'center', height: '100%' }}>
            <Box
              component="img"
              src={ContactImage}
              alt="Contact"
              sx={{ width: '100%', height: 'auto', borderRadius: '8px', mb: 2 }}
            />
            <Typography variant="h5" gutterBottom>
              Contact With Me
            </Typography>
            <Typography variant="h6" gutterBottom>
              Shaline Paulraj
            </Typography>
            <Typography variant="body1" gutterBottom>
              Fashion Designer, Bermingham University, U.K
            </Typography>
            <Typography variant="body2" color="textSecondary" paragraph>
              I am available for freelance work. Connect with me via and call in to my account.
            </Typography>
            <Typography variant="body2" gutterBottom>
              Phone: +91 9840348428
            </Typography>
            <Typography variant="body2" gutterBottom>
              Email: saishalini.fd@gmail.com
            </Typography>
            <Box mt={2}>
              <Typography variant="body1" gutterBottom>
                FIND WITH ME
              </Typography>
              <IconButton href="https://facebook.com" target="_blank" aria-label="Facebook">
                <FacebookIcon />
              </IconButton>
              <IconButton href="https://twitter.com" target="_blank" aria-label="Twitter">
                <TwitterIcon />
              </IconButton>
              <IconButton href="https://linkedin.com" target="_blank" aria-label="LinkedIn">
                <LinkedInIcon />
              </IconButton>
              <IconButton href="https://instagram.com" target="_blank" aria-label="Instagram">
                <InstagramIcon />
              </IconButton>
            </Box>
          </Paper>
        </Grid>

        {/* Right side: Contact form */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 4, height: '100%' }}>
            <Typography variant="h5" gutterBottom>
              Get In Touch
            </Typography>
            <TextField
              fullWidth
              label="Your Name"
              variant="outlined"
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Phone Number"
              variant="outlined"
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              margin="normal"
              type="email"
              required
            />
            <TextField
              fullWidth
              label="Subject"
              variant="outlined"
              margin="normal"
            />
            <TextField
              fullWidth
              label="Your Message"
              variant="outlined"
              margin="normal"
              multiline
              rows={4}
            />
            <Box mt={2}>
              <Button variant="contained" color="primary" fullWidth>
                Send Message
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
