import React from 'react';
import Fab from '@mui/material/Fab';
import WhatsAppIcon from '@mui/icons-material/WhatsApp'; // WhatsApp Icon
import { styled } from '@mui/material/styles'; // Use styled from @mui/material

// Create a styled component for the WhatsApp button
const StyledFab = styled(Fab)({
  position: 'fixed',
  bottom: 16,
  right: 16,
  zIndex: 1000, // Ensures the button stays on top of other elements
});

const WhatsAppButton = () => {
  const handleClick = () => {
    const phoneNumber = '+919840348428'; // Replace with your phone number in international format
    const message = 'Hello, I have a question!'; // Optional message
    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  return (
    <StyledFab color="primary" onClick={handleClick}>
      <WhatsAppIcon />
    </StyledFab>
  );
};

export default WhatsAppButton;
