import React, { useState } from "react";
import { Box, Grid, Typography, Button, Paper } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BaryBatson from "../../assets/Images/Testimony/BarryBatson.jpg";

const testimonials = [
  {
    img: BaryBatson, // Placeholder image, replace with real ones
    name: "Barry Batson",
    description: "Director for the MA Fashion Management & MA Luxury Brand Management – Senior Lecturer",
    testimonial:
      "Shalini, your presentation is truly impressive! I have no doubt that your business idea will achieve great success."
  },
  {
    img: "https://via.placeholder.com/150", // Another image
    description: "Director for the MA Fashion Management & MA Luxury Brand Management – Senior Lecturer",
    name: "Jane Smith",
    testimonial:
      "The team was extremely professional and provided top-notch service. I couldn’t be happier with the results!"
  },
  {
    img: "https://via.placeholder.com/150", // Another image
    name: "Chris Evans",
    description: "Director for the MA Fashion Management & MA Luxury Brand Management – Senior Lecturer",
    testimonial:
      "This company exceeded my expectations. The level of creativity and dedication to the project was outstanding."
  }
];

export default function TestimonialSlider() {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  // Navigate to the next testimonial
  const handleNext = () => {
    setCurrentTestimonial((prev) =>
      prev === testimonials.length - 1 ? 0 : prev + 1
    );
  };

  // Navigate to the previous testimonial
  const handlePrevious = () => {
    setCurrentTestimonial((prev) =>
      prev === 0 ? testimonials.length - 1 : prev - 1
    );
  };

  const { img, name, description, testimonial } = testimonials[currentTestimonial];

  return (
    <>
    <Typography variant="h5" gutterBottom>
    What Clients Say
      </Typography>
      <Typography variant="h2" gutterBottom>
        Testimonial
      </Typography>
    <Box sx={{ width: "100%", height: "100vh", py: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }} className="testimonial-grid">
        <Grid container spacing={4} alignItems="center">
          {/* Left side: Image */}
          <Grid item xs={12} md={4}>
            <Box
              component="img"
              src={img}
              alt={name}
              sx={{
                width: "100%",
                borderRadius: "8px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)"
              }}
            />
          </Grid>

          {/* Right side: Testimonial */}
          <Grid item xs={12} md={8}>
            <Typography variant="h5" gutterBottom>
              {name}
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {description}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {testimonial}
            </Typography>

            {/* Navigation Buttons */}
            <Box mt={2}>
              <Button
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={handlePrevious}
                sx={{ mr: 2 }}
              >
                Previous
              </Button>
              <Button
                variant="outlined"
                endIcon={<ArrowForwardIcon />}
                onClick={handleNext}
              >
                Next
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
    </>
  );
}
