import {
  Box,
  Container,
  Tab,
  Tabs,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Paper,
  Grid,
  Divider,
  useMediaQuery
} from "@mui/material";
import { useState } from "react";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import "./MyResume.scss";

export default function MyResume() {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const isMobile = useMediaQuery("(max-width:600px)");

  const educationDetails = [
    {
      duration: "2023-2024",
      year: "2023",
      title: "Masters in Fashion Designing",
      institution: "Bermingham City University - United Kingdom",
      score: "3.90/4",
      description:
        "The training provided by universities in order to prepare people to work in various sectors of the economy or areas of culture."
    },
    {
      duration: "2021-2023",
      year: "2021",
      title: "Bachelor in Fashion Designing",
      institution: "Dreamzone Mylopore - Chennai",
      score: "4.75/5",
      description:
        "Higher education is tertiary education leading to the award of an academic degree. Higher education, also called post-secondary education."
    },
    {
      duration: "2021-2023",
      year: "2021",
      title: "B-Tech Information Technology",
      institution: "James College Of Engineering and Technologies - Nagercoil",
      score: "5.00/5",
      description:
        "Secondary education or post-primary education covers two phases on the International Standard Classification of Education scale."
    },
    {
      duration: "2007-2009",
      year: "2007",
      title: "Diplomo in Information Technology",
      institution: "Sun Engineering College - Nagercoil",
      score: "5.00/5",
      description:
        "Secondary education or post-primary education covers two phases on the International Standard Classification of Education scale."
    }
  ];
  const splitArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  const splitEducationDetails = splitArray(educationDetails, 3);
  console.log(splitEducationDetails);
  const CustomStepIcon = (props) => {
    const { active, completed, icon } = props;

    return (
      <Typography
        fontSize={"10px"}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        {icon}
      </Typography>
    );
  };

  return (
    <Container>
      <Typography variant="h5" gutterBottom>
        12+ Years of Experience
      </Typography>
      <Typography variant="h2" gutterBottom>
        My Resume
      </Typography>
      <Box
      sx={{ width: "100%", typography: "body1" }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="resume tabs"
        variant={isMobile ? "scrollable" : "standard"} // Scrollable tabs for mobile view
        scrollButtons={isMobile ? "auto" : false} // Show scroll buttons on mobile
        centered={!isMobile} // Center tabs on non-mobile views
        allowScrollButtonsMobile // Allow scroll buttons for mobile screens
      >
        <Tab label="Education" value="1" />
        <Tab label="Professional Skills" value="2" />
        <Tab label="Experience" value="3" />
        <Tab label="Interview" value="4" />
      </Tabs>
    </Box>

      {/* Conditionally render content based on selected tab */}
      {value === "1" && (
        <Box mt={4}>
          <Grid container spacing={4}>
            {splitEducationDetails?.map((edudetails, i) => {
              return (
                <Grid item xs={12} md={6} index={i}>
                  <Stepper orientation="vertical" index={i}>
                    {edudetails.map((education, index) => (
                      <Step key={education.year} active={true}>
                        <StepLabel
                          StepIconComponent={() => (
                            <CustomStepIcon
                              icon={<Typography>{education.year}</Typography>}
                            />
                          )}
                        />
                        <StepContent>
                          <Paper
                            elevation={3}
                            sx={{ padding: 2, marginBottom: 2 }}
                            className="resume-details"
                            style={{ textAlign: "left" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom:"2px"
                              }}
                            >
                              <span style={{ fontSize: "12px" }}>

                            <Typography variant="h6" mb={2}>
                              {education.title}
                            </Typography> ({education.institution})
                              </span>
                              <span
                                className="resume-score"
                                style={{ fontSize: "12px", borderRadius:"4px" }}
                              >
                                {education.score}
                              </span>
                            </div>
                            <Divider />
                            <Typography variant="body2" mt={2}>
                              {education.description}
                            </Typography>
                          </Paper>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      )}

      {value === "2" && (
        <Box mt={4}>
          {/* Professional Skills content can be added here */}
          <Typography variant="h6">
            Professional Skills content here...
          </Typography>
        </Box>
      )}

      {value === "3" && (
        <Box mt={4}>
          {/* Experience content can be added here */}
          <Typography variant="h6">Experience content here...</Typography>
        </Box>
      )}

      {value === "4" && (
        <Box mt={4}>
          {/* Interview content can be added here */}
          <Typography variant="h6">Interview content here...</Typography>
        </Box>
      )}
    </Container>
  );
}
