import { List, ListItem, ListItemText, Paper } from "@mui/material";
import './Sidebar.scss'
import { NavLink } from "react-router-dom";
export default function Sidebar(){
    return(
        <Paper className="sidebar" style={{ padding: '10px', height: '100vh' }}>
            <h1 className="sidebar-logo"><a href="/">Shalini<span>.</span></a></h1>
            <div className="sidebar-nav">
            <List>
              <ListItem button component={NavLink} to="/" activeClassName="active">
                <ListItemText primary="Home" />
              </ListItem>
              <ListItem button component={NavLink} to="/features" activeClassName="active">
                <ListItemText primary="Features" />
              </ListItem>
              <ListItem button component={NavLink} to="/myresume" activeClassName="active">
                <ListItemText primary="My Resume" />
              </ListItem>
              <ListItem button component={NavLink} to="/testimony" activeClassName="active">
                <ListItemText primary="Testimony" />
              </ListItem>
              <ListItem button component={NavLink} to="/clients" activeClassName="active">
                <ListItemText primary="Happy Clients" />
              </ListItem>
              <ListItem button component={NavLink} to="/about" activeClassName="active">
                <ListItemText primary="About" />
              </ListItem>
              <ListItem button component={NavLink} to="/contact" activeClassName="active">
                <ListItemText primary="Contact" />
              </ListItem>
            </List>
            </div>
          </Paper>
    )
}