import React, { useState } from "react";
import { Grid, Paper, IconButton, Drawer, List, ListItem, ListItemText, useMediaQuery, useTheme } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import './App.scss';
import Sidebar from "./Components/Sidebar/Sidebar";
import Home from "./Pages/Home/Home";
import Features from "./Pages/Features/Features";
import { NavLink, Route, Routes } from "react-router-dom";
import MyResume from "./Pages/MyResume/MyResume";
import { WhatsApp } from "@mui/icons-material";
import WhatsAppButton from "./Components/WhatsAppButton/WhatsAppButton"
import Gallery from "./Pages/Gallery/Gallery";
import TestimonialSlider from "./Components/TestimonialSlider/TestimonialSlider";
import Contacts from "./Pages/Contacts/Contacts";
import ClientPage from "./Pages/Clients/Clients";

function App() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const drawer = (
    <div role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <List>
        <ListItem button component={NavLink} to="/">
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component={NavLink} to="/features">
          <ListItemText primary="Features" />
        </ListItem>
        <ListItem button component={NavLink} to="/myresume">
          <ListItemText primary="My Resume" />
        </ListItem>
        <ListItem button component={NavLink} to="/testimony">
          <ListItemText primary="Testimony" />
        </ListItem>
        <ListItem button component={NavLink} to="/clients">
          <ListItemText primary="Happy Clients" />
        </ListItem>
        <ListItem button component={NavLink} to="/about">
          <ListItemText primary="About" />
        </ListItem>
        <ListItem button component={NavLink} to="/contact">
          <ListItemText primary="Contact" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div className="App">
      {isMobile && (
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer(true)}
          className="menu-button"
        >
          <MenuIcon />
        </IconButton>
      )}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        className="sidebar-drawer"
      >
        {drawer}
      </Drawer>
      <Grid container spacing={1}>
        {/* Sidebar for larger screens */}
        {!isMobile && (
          <Grid item xs={12} md={3} className="desktop-sidebar">
            <Sidebar />
          </Grid>
        )}
        
        {/* Main Body */}
        <Grid item xs={12} md={9}>
          <Paper style={{ padding: '10px', textAlign: 'center', height: '100vh',overflow:"scroll" }}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/features" element={<Features />} />
              <Route path="/myresume" element={<MyResume />} />
              <Route path="/gallery/:title" element={<Gallery />} />
              <Route path="/testimony" element={<TestimonialSlider />} />
              <Route path="/contact" element={<Contacts />} />
              <Route path="/clients" element={<ClientPage />} />
              {/* <Route path="/mywork" element={<MyWork />} /> */}
            </Routes>
          </Paper>
        </Grid>
      </Grid>
      <WhatsAppButton></WhatsAppButton>
    </div>
  );
}

export default App;
