import React from "react";
import { useLocation } from "react-router-dom";
import { Container, ImageList, ImageListItem, ImageListItemBar, Typography, useMediaQuery } from "@mui/material";

export default function Gallery() {
  const location = useLocation();
  const { feature } = location.state; // Get the feature data passed from Features.js
  
  // Media query to adjust columns based on screen size
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:960px)");

  // Determine the number of columns based on screen size
  const getCols = () => {
    if (isMobile) return 1;
    if (isTablet) return 2;
    return 3; // Default for larger screens
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        {feature.title} Gallery
      </Typography>
      <ImageList sx={{ width: '100%', height: 'auto' }} cols={getCols()} gap={16}>
        {feature.galleryImages.map((img, index) => (
          <ImageListItem key={index}>
            <img
              src={img}
              alt={`Gallery ${index}`}
              loading="lazy"
              style={{ borderRadius: '8px', objectFit: 'cover' }}
            />
            <ImageListItemBar
              title={`Image ${index + 1}`} // Dynamic title, can be modified
              subtitle={<span>{feature.title}</span>}
              position="below"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Container>
  );
}
