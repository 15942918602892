import { Container, ImageList, ImageListItem, Typography, useMediaQuery } from "@mui/material";
import "./Features.scss";
import authorImg from "../../assets/Images/author.jpg"; // Ensure this path is correct
import fashionshow from "../../assets/Images/Features/FashionShow.jpg";
import fashionshow1 from "../../assets/Images/Gallery/FashionShow1.jpg";
import fashionshow2 from "../../assets/Images/Gallery/FashionShow2.jpg";
import fashionshow3 from "../../assets/Images/Gallery/FashionShow3.jpg";
import fashionshow4 from "../../assets/Images/Gallery/FashionShow4.jpg";
import fashionshow5 from "../../assets/Images/Gallery/FashionShow5.jpg";
import womenscasualwear from "../../assets/Images/Features/WomensCasualWear.jpg";
import kidswear from "../../assets/Images/Features/KidsWear.jpg";
import skirsandtops from "../../assets/Images/Features/Collaboration.jpg";
import collaboration from "../../assets/Images/Features/Collaborations.jpg";
import embroidery from "../../assets/Images/Features/Embroidery.jpg";
import bridal from "../../assets/Images/Features/bridal.jpg";
import FabricPainting from "../../assets/Images/Features/FabricPainting.jpg";
import ArtsAndCrafts from "../../assets/Images/Features/ArtsAndCrafts.jpg";
import CustomizedGifts from "../../assets/Images/Features/CustomizedGifts.jpg";
import { useNavigate } from "react-router-dom";

const featureData = [
  {
    title: "Fashion Show",
    img: fashionshow,
    galleryImages:[
      fashionshow,
      fashionshow1,
      fashionshow2,
      fashionshow3,
      fashionshow4,
      fashionshow5
    ],
    description:
      "Organizing and participating in fashion shows to showcase the latest trends and designs, providing a platform for creativity and innovation in the fashion industry."
  },
  {
    title: "Collaboration",
    img: collaboration,
    galleryImages:[
      authorImg,
      authorImg,
      authorImg
    ],
    description:
      "Collaborating with designers, brands, and artists to create unique and impactful projects that push the boundaries of fashion and creativity."
  },{
    title: "Kids Wear",
    img: kidswear,
    description:
      "A delightful range of kids wear that combines comfort with playful designs. From everyday essentials to special occasion outfits, our collection is designed to keep children looking cute and feeling great."
  },
  {
    title: "Embroidery",
    img: embroidery,
    description:
      "Offering custom embroidery services that bring your designs to life with a distinctive touch for apparel, accessories, and special projects."
  },
  {
    title: "Bridal Wear",
    img: bridal,
    description:
      "Elegant and bespoke bridal wear tailored to your style, ensuring a memorable and beautiful experience on your special day."
  },
  {
    title: "WOMEN’S CASUAL WEAR",
    img: womenscasualwear,
    description:
      "Stylish and comfortable casual wear designed for everyday elegance and ease."
  },
  {
    title: "SKIRTS & TOPS",
    img: skirsandtops,
    description:
      "A wide range of skirts and tops that blend fashion with functionality, perfect for any occasion."
  },
  {
    title: "FABRIC PAINTING",
    img: FabricPainting,
    description:
      "Creative and personalized fabric painting services that add a unique touch to your garments and accessories."
  },
  {
    title: "ARTS & CRAFTS",
    img: ArtsAndCrafts,
    description:
      "Handmade arts and crafts that bring a touch of creativity and uniqueness to your home and wardrobe."
  },
  {
    title: "CUSTOMIZED GIFT",
    img: CustomizedGifts,
    description:
      "Thoughtful and customized gifts crafted to meet your personal preferences and special occasions."
  },
  {
    title: "RECYCLING PROJECT",
    description:
      "Innovative recycling projects that promote sustainability and transform old items into new, useful products."
  }
];

export default function Features() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const handleClick = (feature) => {
    // Navigate to gallery page with the feature details
    navigate(`/gallery/${feature.title}`, { state: { feature } });
  };

  return (
    <Container>
      <Typography variant="h5" gutterBottom>
        Features
      </Typography>
      <Typography variant="h2" gutterBottom>
        What I Do
      </Typography>
      <ImageList cols={isMobile ? 1 : 3} gap={16}>
        {featureData.map((feature, index) => (
          <ImageListItem key={index} className="features-card" onClick={() => handleClick(feature)}>
            <div className="image-container">
              <img
                src={feature?.img ? feature?.img : authorImg}
                alt={feature.title}
                className="feature-image"
              />
            </div>
            <div className="title-bar">
              <Typography variant="h6" component="div" className="title">
                {feature.title}
              </Typography>
              <Typography
                variant="body2"
                component="div"
                className="description"
              >
                {feature.description}
              </Typography>
            </div>
          </ImageListItem>
        ))}
      </ImageList>
    </Container>
  );
}
