import React, { useState } from "react";
import "./Home.scss";
import AuthorImg from "../../assets/Images/author.jpg";
import {
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText
} from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import WorkIcon from "@mui/icons-material/Work";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import DownloadIcon from "@mui/icons-material/Download";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const [open, setOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate= useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };
  const myWorkHandle = ()=>{
    navigate("/features")
  }
  const letTalkHandle = () =>{
    navigate("/contact")
  }
  return (
    <div className="home-container">
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid
          item
          xs={12}
          md={8}
          className="profile-section"
        >
          <div className="image-wrapper">
            <img
              src={AuthorImg}
              alt="profile"
              className="circular-image"
              onClick={handleClickOpen}
            />
          </div>

          <Dialog open={open} onClose={handleClose} maxWidth="md">
            <img
              src={AuthorImg}
              alt="profile"
              style={{ width: "auto", height: "100vh" }}
            />
          </Dialog>

          <div className="description">
            <Typography variant="h5" className="greeting">
              Hello, I'm
            </Typography>
            <Typography variant="h2" className="name" gutterBottom>
              Shalini Paulraj
            </Typography>
            <Typography variant="h4" className="profession">
              Fashion Designer |
              <span className="university">
                {" "}
                Birmingham City University (UK)
              </span>
            </Typography>
          </div>

          <Stack direction="row" spacing={2} justifyContent="center" mt={4} className="letstalkbutton">
            <Button
              variant="outlined"
              startIcon={<ChatIcon />}
              onClick={letTalkHandle}
              sx={{
                color: "black",
                borderColor: "black",
                transition: "transform 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                  borderColor: "black",
                  transform: "scale(1.1)"
                }
              }}
            >
              Let's Talk
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "black",
                color: "white",
                transition: "transform 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor: "white",
                  color: "black",
                  borderColor: "black",
                  transform: "scale(1.1)"
                }
              }}
              endIcon={<WorkIcon />}
              onClick={myWorkHandle}
            >
              My Work
            </Button>
          </Stack>

          <div className="bottom-buttons">
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "bold",
                  color: "primary.main",
                  mr: 4
                }}
              >
                50+ Worldwide Clients
              </Typography>

              <Divider orientation="vertical" variant="middle" flexItem />

              <Typography
                variant="body1"
                sx={{
                  fontWeight: "bold",
                  color: "text.secondary",
                  ml: 4
                }}
              >
                12+ Years of Experience
              </Typography>

              <Divider orientation="vertical" variant="middle" flexItem />

              <Typography
                variant="body1"
                sx={{
                  fontWeight: "bold",
                  color: "text.secondary",
                  ml: 4
                }}
              >
                100+ Projects Completed
              </Typography>
            </Stack>

            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
              mt={3}
              className="social-icons"
            >
              <IconButton href="https://www.facebook.com/saishalini.designer" target="_blank">
                <FacebookIcon />
              </IconButton>
              <IconButton href="https://twitter.com" target="_blank">
                <TwitterIcon />
              </IconButton>
              <IconButton href="https://www.instagram.com/saishalini.designer" target="_blank">
                <InstagramIcon />
              </IconButton>
              <IconButton href="https://www.linkedin.com/in/saishalini" target="_blank">
                <LinkedInIcon />
              </IconButton>
            </Stack>

            <Button
              variant="contained"
              color="black"
              className="resume-button"
              endIcon={<DownloadIcon />}
            >
              Download Resume
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
