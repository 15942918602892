import React, { useState } from 'react';
import { Box, Typography, Tabs, Tab, Card, CardContent, CardMedia, Grid, Paper, useMediaQuery, useTheme, Divider } from '@mui/material';
import preethi from '../../assets/Images/Features/WomensCasualWear.jpg'
import nithyaraj from '../../assets/Images/Clients/Nithyaraj.jpg'
import rithvika from '../../assets/Images/Clients/Rithvika.jpg'
import KomathiMohan from '../../assets/Images/Clients/KomathiMohan.jpg'

// Sample data for categories and clients
const categories = ['All', 'Customised Outfit', 'Kids Wear', 'Western','Gown'];

const clients = [
  { name: 'Preethi', category: 'Western', place:"Chennai", img: preethi},
  { name: 'Rithvika', category: 'Kids Wear', place:"Bangalore", img: rithvika },
  { name: 'Komathi Mohan', category: 'Gown', place:"Chennai", img: KomathiMohan },
  { name: 'Nithyraj', category: 'Customised Outfit', place:"Chennai", img: nithyaraj },
];

export default function ClientPage() {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (event, newValue) => {
    setSelectedCategory(newValue);
  };

  const filteredClients = selectedCategory === 'All'
    ? clients
    : clients.filter(client => client.category === selectedCategory);

  return (
    <>
    <Typography variant="h5" gutterBottom>
    Popular Clients
      </Typography>
      <Typography variant="h2" gutterBottom>
        Awesome Clients
      </Typography>
    <Box sx={{ width: '100%', py: 4 }}>
      <Paper elevation={2} sx={{ mb: 4 }}>
        <Tabs
          value={selectedCategory}
          onChange={handleChange}
          aria-label="client categories"
          variant={isSmallScreen ? 'scrollable' : 'standard'}
          scrollButtons={isSmallScreen ? 'auto' : 'hidden'}
          sx={{ overflowX: 'auto' }}
        >
          {categories.map((category) => (
            <Tab key={category} value={category} label={category} />
          ))}
        </Tabs>
      </Paper>

      <Grid container spacing={4}>
        {filteredClients.map((client, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
              <CardMedia
                component="img"
                image={client.img}
                alt={client.name}
                sx={{ width: 150, height: 150, borderRadius: '50%', mb: 2 }}
              />
              <Divider></Divider>
              <CardContent>
                <Typography variant="h5" component="div">
                  {client.name}
                </Typography>
                <Typography variant="h6" component="div">
                  {client.place}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
    </>
  );
}
